<template>
    <v-layout class="row-status" ma-2 justify-center grow>
      <v-card class="status-message">
        <v-layout grow justify-center>
          <v-layout class="box-status" column>
            <span class="status-text">
              {{ translatedChangedBy }} mudou o status para {{ translatedStatus }} ({{ formattedDate(event.created_at) }})
            </span>
          </v-layout>
        </v-layout>
      </v-card>
    </v-layout>
  </template>
  
  <script>
  import moment from "moment";
  
  export default {
    props: ["event"], // A prop "event" já é um único evento recebido pelo componente
    computed: {
      translatedStatus() {
        // Traduções para o status
        const statusTranslation = {
          AUTO: "ATENDIMENTO AUTOMÁTICO",
          WAITING_TREATMENT: "AGUARDANDO TRATAMENTO",
          WAITING_CUSTOMER: "AGUARDANDO CLIENTE",
          PENDING: "PENDENTE",
          CLOSING: "AGUARDANDO PARA FECHAR",
          CLOSED: "FECHADO"
        };
  
        // Retorna a tradução, se existir; caso contrário, retorna o valor original
        return statusTranslation[this.event.status] || this.event.status;
      },
      translatedChangedBy() {
        // Ajusta o CHANGED_BY, se for "External app"
        return this.event.changed_by === "External app" ? "Rasa" : this.event.changed_by;
      },
    },
    methods: {
      formattedDate(createdAt) {
        return moment(createdAt).format("DD/MM/YYYY - HH:mm");
      },
    },
  };
  </script>
  
  <style scoped>
  .row-status {
    max-height: max-content;
    padding: 0;
    justify-content: center;
  
    .status-message {
      border-radius: 12px;
      background-color: #e9ecef;
      padding: 10px 15px;
      text-align: center;
      font-size: 14px;
      color: #495057;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  
    .status-text {
      font-weight: 500;
    }
  }
  </style>
  